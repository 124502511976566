/* Custom functions on bottom /*

/* * * * * * * * * */
/* Base            */
/* * * * * * * * * */

  fx_base = {

    _construct: function() {
      _bThis = this;

      //--

      $footer          = $('footer');
      $html            = $('html');
      $smainContainer  = $('.smain-container');
    },

    _setEnvironment: function() {

      /* This function defines the height of
        the main container by placing the footer
        at the bottom of the site, use the corresponding
        classes according to your needs. */

      _bThis.getSizes();

      switch(true) {

        /*Classes for smain-container:

        min-screen -> Default setting, this class doesn't exist
        max-screen
        min-full-screen
        max-full-screen*/

        //----------------

        // Max height // With Footer
        case ($smainContainer.hasClass('max-screen')):
          $smainContainer
          .css({
            'height': siteHeightWithFooter
          });
          /*if(smainContainerHeight <= smainContainerRealHeight && siteHeight <= smainContainerRealHeight) {
            $smainContainer
            .css({
              'min-height': smainContainerRealHeight
            });
          }*/
        break;

        // Min height // Without Footer
        case ($smainContainer.hasClass('min-full-screen')):
          $smainContainer
          .css({
            'min-height': siteHeightWithoutFooter
          });
        break;

        // Max height // Without Footer
        case ($smainContainer.hasClass('max-full-screen')):
          $smainContainer
          .css({
            'height': siteHeightWithoutFooter
          });
          /*if(smainContainerHeight <= smainContainerRealHeight && siteHeight <= smainContainerRealHeight) {
            $smainContainer
            .css({
              'min-height': smainContainerRealHeight - footerHeight,
            });
          }*/
        break;

        // Default // Min height // With Footer
        default:
          $smainContainer

          .css({
            'min-height': siteHeightWithFooter,
          });
        break;
      }
    },

    _setSizes: function() {

      footerHeight             = $footer.outerHeight();
      siteHeight               = $html.outerHeight();
      smainContainerHeight     = $smainContainer.outerHeight();
      smainContainerRealHeight = $smainContainer[0].scrollHeight;

      siteHeightWithoutFooter  = siteHeight;
      siteHeightWithFooter     = siteHeight - footerHeight;
    },

    environment: function() {
      this._construct();

      //--

      _bThis._setEnvironment();

      $(window)
      .resize(function() {
        _bThis._setEnvironment();
      });
    },

    getSizes: function() {
      this._construct();

      //--

      _bThis._setSizes();

      $(window)
      .resize(function() {
        _bThis._setSizes();
      });

      return {
        'footerHeight'             : footerHeight,
        'siteHeight'               : siteHeight,

        'smainContainerHeight'     : smainContainerHeight,
        'smainContainerRealHeight' : smainContainerRealHeight,

        'siteHeightWithoutFooter'  : siteHeightWithoutFooter,
        'siteHeightWithFooter'     : siteHeightWithFooter,
      };
    },

    getUrl: function() {
      path       = window.location.pathname;
      pathArray  = window.location.href.split( '/' );
      host       = pathArray[2];
      protocol   = pathArray[0];
      url        = protocol + '//' + host;

      //--

      getParams = window.location.search.substring(1).split('&');

      parameters = {} ;

      $.each(getParams, function(i, item) {
        itemArray = item.split('=');
        parameters[itemArray[0]] = itemArray[1];
      });

      //--

      return {
        'host'        : host,
        'path'        : path,
        'parameters'  : parameters,
        'protocol'    : protocol,
        'url'         : url,
      }
    },
  }

  var baseUrl = fx_base.getUrl().url; // -> Use This

/* * * * * * * * * */

/* * * * * * * * * */
/* messages        */
/* * * * * * * * * */

  /*Errors/Modal of $errors variable*/

  fx_messages = {

    _construct: function() {
      _msgThis = this;

      //--

      $messages   = $('.custom-messages');
      $btn_close  = $('.custom-messages .close');
      $alert      = $('.custom-messages .alert');
    },

    _hide: function(event) {
      event = typeof event !== 'undefined' ? event : null;

      event.preventDefault();
      event.stopPropagation();

      $messages
      .stop()
      .fadeOut();
    },

    init: function() {
      this._construct();

      //--

      $messages
      .on('click', function(event) {
        _msgThis._hide(event);
      });

      $alert
      .on('click', function(event) {
        event.stopPropagation();
      });

      $btn_close
      .on('click', function(event) {
        _msgThis._hide(event);
      })
    },
  }

/* * * * * * * * * */

/* * * * * * * * * */
/* Proportionaly   */
/* * * * * * * * * */

  /*If you are using squares or rectangles,
  these functions can help maintain the minimum
  or maximum proportion of the container to which
  the class is assigned.
  call fx_proportional.make() in the view where necessary*/

  fx_proportional = {

    _construct: function() {
      _propoThis = this;

      //--

      $proportional = $('.proportional'); // -> Use this
    },

    _square: function() {
      $proportional
      .each(function(index) {
        $object = $(this);
        if($object.hasClass('square')) { // -> Use this
          proportional_width  = $object[0].getBoundingClientRect().width;
          $object
          .css('min-height', proportional_width);
        }
      });

      $proportional
      .each(function(index) {
        $object = $(this);
        if($object.hasClass('square-fit')) { // -> Use this
          proportional_width  = $object[0].getBoundingClientRect().width;
          $object
          .css('height', proportional_width);
        }
      });
    },

    _rectangle: function() {
      $proportional
      .each(function(index) {
        $object = $(this);
        if($object.hasClass('rectangle')) { // -> Use this
          proportional_width = Math.round($object[0].getBoundingClientRect().width / 2);
          $object
          .css('min-height', proportional_width);
        }
      });

      $proportional
      .each(function(index) {
        $object = $(this);
        if($object.hasClass('rectangle-fit')) { // -> Use this
          proportional_width = Math.round($object[0].getBoundingClientRect().width / 2);
          $object
          .css('height', proportional_width);
        }
      });
    },

    make: function() {
      this._construct();

      //--

      _propoThis._square();
      _propoThis._rectangle();

      $(window)
      .resize(function() {
        _propoThis._square();
        _propoThis._rectangle();
      });
    }
  }

/* * * * * * * * * * * */

/* * * * * * * * * */
/* Customs inputs  */
/* * * * * * * * * */

  /*If you are using custom inputs or you need to validate any of these
  call fx_inputs.validate() in app.js inside of docReady function.*/

/** Inputs **/

  fx_formFields = {

    _construct: function() {
      _ffThis = this;

      //--

      $fields       = $('input, select, textarea');
      $only_numbers = $('input[type="only_number"]'); // Use only_numbers as type of input
      $files        = $('input[type="file"]');
      $select       = $('select');
    },

    _isEmpty: function($input_field) {
      //-- active
      if($input_field.val()) {
        $input_field
        .addClass('active');
      }
      else{
        $input_field
        .removeClass('active');
      }
      //--
    },

    _validateOnlyNumbers: function($input_field, event) {
      var a = [];
      var k = event.which;

      for (i = 48; i < 58; i++) { // 0 - 9
        a.push(i);
      }

      a.push(13); // Enter
      a.push(46); // Comma
      a.push(44); // Dot
      a.push(08); // Backspace

      if (!(a.indexOf(k)>=0)) {
        event.preventDefault();
      }
    },

    _validateFiles: function($target) {
      file = $target[0].files[0];
      $target.nextAll('label').html(file.name);
    },

    _validateSelect: function($target) {
      select_value = $target.val();
      if(select_value){
        $target.nextAll('label').html(select_value);
      }
    },

    validate: function() {
      this._construct();

      //--

      $only_numbers
      .keypress(function(event) {
        _ffThis._validateOnlyNumbers($(this), event);
      });

      $fields
      .each(function(index) {
        _ffThis._isEmpty($(this));
      });

      $fields
      .on('change', function(event) {
        _ffThis._isEmpty($(this));
      });

      $select
      .each(function(index) {
        _ffThis._validateSelect($(this));
      });

      $select
      .on('change', function(event) {
        _ffThis._validateSelect($(this));
      });

      $files
      .on('change', function(event) {
        _ffThis._validateFiles($(this));
      });
    }
  }

/* * * * * * * * * * * */

/* * * * * * * * * */
/* Symmetrical     */
/* * * * * * * * * */

  /*If you want make symmetrical all items inside a container
  call fx_symmetrical.make() in the view where necessary*/

  fx_symmetrical = {

    _construct: function() {
      _symThis = this;

      //--

      $symmetrical_container = $('.symmetrical-container');
      $symmetrical           = $('.symmetrical');
    },

    _symmetrical: function(disable_on_breakpoint) {

      window_width = $(window).width();

      if(window_width > disable_on_breakpoint) {

        $symmetrical_container
        .each(function(i) {

          a = 0;
          b = 0;

          $symmetrical = $(this).find('.symmetrical');

          $symmetrical
          .each(function(j) {

            $symmetrical
            .css('height', 'auto');

            a = $(this).outerHeight();

            if(a >= b) {
              b = a;
            }

            $symmetrical
            .css('height', b);

          });

        });

      }

      else {
        $symmetrical
        .css('height', 'auto');
      }
    },

    make: function(disable_on_breakpoint) {
      this._construct();

      //--

      _symThis._symmetrical(disable_on_breakpoint);

      $(window)
      .resize(function() {
        _symThis._symmetrical(disable_on_breakpoint);
      });

      $(window)
      .load(function() {
        _symThis._symmetrical(disable_on_breakpoint);
      });
    }
  }

/* * * * * * * * * * * */

fx_scroll = {

  _construct: function() {
    _scrollThis = this;

    //--

    $body = $('body');
  },

  init: function() {
    this._construct();

    //--

    $body
    .niceScroll({
      nativeparentscrolling: false,
      preservenativescrolling: false,
      zindex: 999999999999,
    });
  }
}

fx_menu = {

  _construct: function() {
    _mThis = this;

    //--

    $main_menu            = $('.main-menu');
    $open_nav             = $('.open-nav');
    $nav                  = $('.main-menu.bull nav');
    $smain_container      = $('.smain-container');
    $team_sidebar         = $('.team-sidebar');
    $team_sidebar_content = $('.team-sidebar .content');

    $anchor               = $('.anchor');

    //--

    main_menu_height = $main_menu.outerHeight();
  },

  _openNav: function() {

    main_menu_height = $main_menu.outerHeight();
    sizes            = fx_base.getSizes();

    $open_nav
    .stop()
    .toggleClass('active');

    $nav
    .stop()
    .slideToggle('fast', function() {

      $nav
      .css({
        'max-height': sizes['siteHeight'] - main_menu_height,
        'top': main_menu_height,
      });

    });

  },

  _navResize: function() {

    if($nav.is(':visible')) {

      main_menu_height = $main_menu.outerHeight();
      sizes            = fx_base.getSizes();

      $nav
      .css({
        'height': sizes['siteHeight'],
        'max-height': sizes['siteHeight'] - main_menu_height,
        'top': main_menu_height,
      });

    }

  },

  _navPosition: function() {
    main_menu_height = $main_menu.outerHeight();

    $nav
    .css({
      'top': main_menu_height,
    });

    $smain_container
    .css({
      'padding-top': main_menu_height,
    });

    $team_sidebar
    .css({
      'padding-top': main_menu_height,
    });

    $team_sidebar_content
    .css({
      'padding-top': main_menu_height,
    });

  },

  _anchor: function($this) {
    anchor_target = $this.attr('href');

    $('html, body')
    .animate({
      scrollTop: $(anchor_target).offset().top - 70,
    });
  },

  _nudoNegro: function() {
    $navNudoNegro = $('.main-menu .section-2');
    $slider       = $('.slider-main');
    nav_top = $navNudoNegro.offset().top;

    $(window)
    .on('scroll', function() {
      win_top = $(window).scrollTop();

      if(win_top >= nav_top) {
        $navNudoNegro.addClass('follow-me');
        $slider
        .css('margin-top', $navNudoNegro.outerHeight());
      }
      else {
        $navNudoNegro.removeClass('follow-me');
        $slider
        .css('margin-top', 0);
      }

    });
  },

  _merkava: function() {
    $navMerkava = $('.main-menu nav');
    $slider       = $('.slider-main');
    nav_top = $navMerkava.offset().top;

    $(window)
    .on('scroll', function() {
      win_top = $(window).scrollTop();

      if(win_top >= nav_top) {
        $navMerkava.addClass('follow-me');
       /* $slider
        .css('margin-top', $navMerkava.outerHeight());*/
      }
      else {
        $navMerkava.removeClass('follow-me');
        /*$slider
        .css('margin-top', 0);*/
      }

    });
  },

  _paraguero: function() {
    $navMerkava = $('.main-menu nav');
    $slider       = $('.slider-main');
    nav_top = $navMerkava.offset().top;

    $(window)
    .on('scroll', function() {
      win_top = $(window).scrollTop();

      if(win_top >= nav_top) {
        $navMerkava.addClass('follow-me');
        menuHeight = $('.main-menu').outerHeight();
        $main
        .css('margin-top', -menuHeight);
      }
      else {
        $navMerkava.removeClass('follow-me');
        menuHeight = $('.main-menu').outerHeight();
        $main
        .css('margin-top', -menuHeight);
      }

    });
  },

  init: function() {
    this._construct();

    //--

    $open_nav
    .on('click', function(event) {
      event.preventDefault();
      _mThis._openNav();
    });

    $anchor
    .on('click', function(event) {
      event.preventDefault();
      _mThis._anchor($(this));
    });

    _mThis._navPosition();

    $(window)
    .resize(function() {
      _mThis._navPosition();
      _mThis._navResize();
    });
  }
}


fX_mosaic = {

  _construct: function() {
    _mosThis = this;

    //--

    $tile = $('.mosaic .tile');

    //--

    tiles_quantity = $tile.length;
    rnd_image = 0;
    rnd_temp  = 0;

  },

  _shuffleArray: function(array) { // Save this function in Base.

    for (i = array.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1))
      temp = array[i]
      array[i] = array[j]
      array[j] = temp
    }

    return array;
  },

  _shuffleDraw: function() {

    rnd_tile  = Math.floor(Math.random() * (tiles_quantity + 1))

    //--

    if(rnd_temp != rnd_tile) {

      $($tile[rnd_tile])
      .addClass('effect');

      setTimeout(function() {

        $($tile[rnd_tile])
        .css({
          'background-image': 'url(' + baseUrl + '/' + shuffle[rnd_image] + ')',
        })

        $($tile[rnd_tile]).removeClass('effect');

      }, 500);

      //--

      rnd_image = (rnd_image >= shuffle.length - 1) ? 0 : rnd_image + 1;
      rnd_temp  = rnd_tile;

    }

  },

  _draw: function() {

    $tile
    .each(function(index) {

      $(this)
      .css({
        'background-image': 'url(' + baseUrl + '/' + shuffle[index] + ')',
      });
    });
  },

  make: function(mosaic) {
    this._construct();

    //--

    mosaic = JSON.parse(mosaic)
    shuffle  = _mosThis._shuffleArray(mosaic);

    //--

    _mosThis._draw();

    setInterval(function() {
      _mosThis._shuffleDraw();
    }, 1000);

  }
}

fx_downloadFile = {

  _construct: function() {

    //--
  },

  catalogue: function(file_path) {
    var link=document.createElement('a');
    link.href = file_path;
    link.download = file_path.substr(file_path.lastIndexOf('/') + 1);
    link.click();
  }
}

fx_teamSidebar = {

  _construct: function() {
    _teamSideThis = this;

    //--

    $open_sidebar_team = $('.open-sidebar-team');
    $team_sidebar      = $('.team-sidebar');
    $ts_content        = $('.team-sidebar .content');
    $ts_close          = $('.team-sidebar .close');

    $team_sidebar_name = $team_sidebar.find('.name');
    $team_sidebar_job  = $team_sidebar.find('.job');
    $team_sidebar_desc = $team_sidebar.find('.desc');

  },

  _getInfo: function($target) {
    $team_sidebar_name.html($target.attr('name'));
    $team_sidebar_job.html($target.attr('job'));
    $team_sidebar_desc.html($target.attr('desc'));

    $ts_content.scrollTop(0);
  },

  _openTeamSidebar: function($target) {

    if($team_sidebar.hasClass('active')) {
      $team_sidebar
      .removeClass('active');

      setTimeout(function() {
        _teamSideThis._getInfo($target);
        $team_sidebar
        .addClass('active');
      }, 200);
    }

    else {
      _teamSideThis._getInfo($target);
      $team_sidebar
      .addClass('active');
    }
  },

  init: function() {
    this._construct();

    //--

    $open_sidebar_team
    .on('click', function(event) {
      event.preventDefault();

      _teamSideThis._openTeamSidebar($(this));
    });

    $ts_close
    .on('click', function(event) {
      event.preventDefault();
      $team_sidebar
      .removeClass('active');
    });
  }
}

fx_components = {

  _construct: function() {
    _comThis = this;

    //--

    $open_dropdown = $('.open-dropdown');

  },

  dropdown: function() {
    this._construct();

    //--

    $open_dropdown
    .on('click', function(event) {
      event.preventDefault();

      $open_dropdown
      .not(this)
      .removeClass('active')
      .parents('li')
      .find('.hide')
      .stop()
      .slideUp('fast');

      $(this)
      .toggleClass('active')
      .parents('li')
      .find('.hide')
      .stop()
      .slideToggle('fast');

    });
  }
}


// Restaurants

// ... Nudo negro ... //

fx_slider_nudo_negro = {

  _construct: function() {
    _sldThis = this;

    //--

    $main       = $('.owl-main');
    $carousel_1 = $('.owl-carousel-1');
    $carousel_2 = $('.owl-carousel-2');
  },

  main: function() {
    this._construct();

    //--

    $main
    .owlCarousel({
      autoplay: true,
      autoplayHoverPause: true,
      dots: true,
      items: 1,
      loop: true,
      margin: 0,
      nav: false,
    });
  },

  carousel1: function() {
    this._construct();

    //--

    $carousel_1
    .owlCarousel({
      autoplay: true,
      autoplayHoverPause: true,
      dots: false,
      items: 2,
      loop: true,
      nav: true,
      navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
      responsive:{

        600:{
          items: 2,

        },

        0:{
          items: 1,

        }
      },
    });
  },

  carousel2: function() {
    this._construct();

    //--

    $carousel_2
    .owlCarousel({
      autoplay: true,
      autoplayHoverPause: true,
      dots: false,
      items: 1,
      loop: true,
      nav: true,
      navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
    });
  },
}

fx_instagram_nudo_negro = {

  _construct: function(){
    $instagram          = $('.instagram-container');
    $instagram_template = $('#instagram-template');
  },

  load: function(){
    this._construct();

    //--

    $instagram
    .on('willLoadInstagram', function(event, options) {  });

    $instagram
    .on('didLoadInstagram', function(event, response) {
      var compiled = _.template($instagram_template.html());
      $(this).html(compiled({ items: response.data }));
    });

    $instagram
    .instagram({
      userId: '1680729170',
      accessToken: '1680729170.95edc3c.234150fd578442aea7d29763cacbda08',
      count: 6,
    });
  }
}

// ... Merkava ... //

fx_slider_merkava = {

  _construct: function() {
    _sldThis = this;

    //--

    $main       = $('.owl-main');
    $carousel_1 = $('.owl-carousel-1');
    $carousel_2 = $('.owl-carousel-2');
  },

  main: function() {
    this._construct();

    //--

    $main
    .owlCarousel({
      autoplay: true,
      autoplayHoverPause: true,
      dots: true,
      items: 1,
      loop: true,
      margin: 0,
      nav: false,
    });
  },

  carousel1: function() {
    this._construct();

    //--

    $carousel_1
    .owlCarousel({
      autoplay: true,
      autoplayHoverPause: true,
      dots: false,
      items: 3,
      loop: true,
      nav: true,
      navText: [" ", " "],
      responsive:{

        1024:{
          items: 3,

        },

        768:{
          items: 2,

        },

        0:{
          items: 1,

        }
      },
    });
  },
}

fx_instagram_merkava = {

  _construct: function(){
    $instagram          = $('.instagram-container');
    $instagram_template = $('#instagram-template');
  },

  load: function(){
    this._construct();

    //--

    $instagram
    .on('willLoadInstagram', function(event, options) {  });

    $instagram
    .on('didLoadInstagram', function(event, response) {
      var compiled = _.template($instagram_template.html());
      $(this).html(compiled({ items: response.data }));
    });

    $instagram
    .instagram({
      userId: '3230927905',
      accessToken: '3230927905.2e12022.31d05be0a35144b790727e21c0336e75',
      count: 6,
    });
  }
}

// ... Paraguero ... //

fx_slider_paraguero = {

  _construct: function() {
    _sldThis = this;

    //--

    $main       = $('.owl-main');
    $carousel_1 = $('.owl-carousel-1');
    $carousel_2 = $('.owl-carousel-2');
    $carousel_3 = $('.owl-carousel-3');
    $carousel_4 = $('.owl-carousel-4');
    $carousel_5 = $('.owl-carousel-5');
  },

  main: function() {
    this._construct();

    //--
    //

    menuHeight = $('.main-menu').outerHeight();
    $main
    .css('margin-top', -menuHeight);

    $main
    .owlCarousel({
      autoplay: true,
      /*autoplayHoverPause: true,*/
      dots: true,
      items: 1,
      loop: true,
      margin: 0,
      nav: false,
    });

    $(window)
    .resize(function() {
      menuHeight = $('.main-menu').outerHeight();
      $main
      .css('margin-top', -menuHeight);
    });
  },

  carousel1: function() {
    this._construct();

    //--

    $carousel_1
    .owlCarousel({
      autoplay: true,
      autoplayHoverPause: true,
      dots: false,
      items: 3,
      loop: true,
      nav: true,
      navText: [" ", " "],
      responsive:{

        1024:{
          items: 3,

        },

        768:{
          items: 2,

        },

        0:{
          items: 1,

        }
      },
    });
  },

  carousel2: function() {
    this._construct();

    //--

    $carousel_2
    .owlCarousel({
      autoplay: true,
      autoplayHoverPause: true,
      dots: false,
      items: 1,
      loop: true,
      nav: true,
      navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
    });
  },

  carousel3: function() {
    this._construct();

    //--

    $carousel_3
    .owlCarousel({
      autoplay: true,
      autoplayHoverPause: true,
      dots: false,
      items: 3,
      loop: true,
      nav: true,
      navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
      responsive:{

        1024:{
          items: 3,

        },

        600:{
          items: 2,

        },

        0:{
          items: 1,

        }
      },
    });
  },

  carousel4: function() {
    this._construct();

    //--

    $carousel_4
    .owlCarousel({
      autoplay: true,
      autoplayTimeout:2000,
      /*autoplaySpeed: 800,*/
      autoplayHoverPause: true,
      dots: false,
      items: 4,
      loop: true,
      nav: false,
      responsive:{

        1024:{
          items: 4,

        },

        600:{
          items: 3,

        },

        0:{
          items: 1,

        }
      },
    });
  },

  carousel5: function() {
    this._construct();

    //--

    $carousel_5
    .owlCarousel({
      autoplay: true,
      autoplayTimeout:3000,
      /*autoplaySpeed: 800,*/
      autoplayHoverPause: true,
      dots: false,
      items: 4,
      loop: true,
      nav: false,
      rtl:true,
      responsive:{

        1024:{
          items: 4,

        },

        600:{
          items: 3,

        },

        0:{
          items: 1,

        }
      },
    });
  },
}

fx_instagram_paraguero = {

  _construct: function(){
    $instagram          = $('.instagram-container');
    $instagram_template = $('#instagram-template');
  },

  load: function(){
    this._construct();

    //--

    $instagram
    .on('willLoadInstagram', function(event, options) {  });

    $instagram
    .on('didLoadInstagram', function(event, response) {
      var compiled = _.template($instagram_template.html());
      $(this).html(compiled({ items: response.data }));
    });

    $instagram
    .instagram({
      userId: '3908903755',
      accessToken: '3908903755.d96ac73.cda59f91515f42f59f6e21b52dede165',
      count: 6,
    });
  }
}


// ... Peltre ... //

fx_slider_peltre = {

  _construct: function() {
    _sldThis = this;

    //--

    $main       = $('.owl-main');
    $carousel_1 = $('.owl-carousel-1');
    $carousel_2 = $('.owl-carousel-2');
  },

  main: function() {
    this._construct();

    //--

    $main
    .owlCarousel({
      autoplay: true,
      autoplayHoverPause: true,
      dots: false,
      items: 1,
      loop: true,
      margin: 0,
      nav: true,
      navText: ["", ""]
    });
  },

  carousel1: function() {
    this._construct();

    //--

    $carousel_1
    .owlCarousel({
      autoplay: false,
      autoplayHoverPause: true,
      dots: false,
      items: 3,
      loop: true,
      nav: true,
      navText: ["", ""],
      responsive:{

        1024:{
          items: 3
        },

        600:{
          items: 2
        },

        0:{
          items: 1
        }
      }
    });
  },

  carousel2: function() {
    this._construct();

    //--

    $carousel_2
    .owlCarousel({
      autoplay: false,
      autoplayHoverPause: true,
      dots: false,
      items: 1,
      loop: true,
      nav: true,
      navText: ["", ""],
      responsive:{

        1200:{
          dots:false,
          nav:true
        },

        0:{
          dots:true,
          nav:false
        }
      }
    });
  }
}

fx_gallery_grid_peltre = {

  _construct: function(){
    $grid_horizontal = $('.grid.grid--horizontal');
    $grid_vertical = $('.grid.grid--vertical');
    fxGG = this;
  },

  _horizontal: function($this){
    $this.find('.master').wrap("<div class='in-block in-block--master' />");
    $this.find('a:not(.master)').wrapAll("<div class='in-block' />");
  },

  _vertical: function($this){
    $this.find('.master').wrap("<div class='in-inline-block in-inline-block--master' />");
    $this.find('a:not(.master)').wrapAll("<div class='in-inline-block' />");
  },

  init: function(){
    this._construct();
    // --

    if( $grid_horizontal.length >= 0 ){
      $grid_horizontal.each(function(){
        fxGG._horizontal( $(this) );
      });
    }

    if( $grid_vertical.length >= 0 ){
      $grid_vertical.each(function(){
        fxGG._vertical( $(this) );
      });
    }
  }
}

fx_linksTabs_peltre = {

  _construct: function(){
    $trigger = $('.link-trigger');
    $target = $('.links-target');
    fxLT = this;
  },

  _shuffle: function( $this ){
    prefix = $this.attr('data-prefix');
    toReplace = $this.attr('data-replace');

    if( !$this.hasClass('active') ){

      $trigger.filter('.active').removeClass('active');

      $target.find('a').each(function(){
        link = $(this).attr('href');
        link = link.replace(toReplace, prefix);
        $(this).attr('href', link);
      });

      $this.addClass('active');
      $target.toggleClass('active');

    }

  },

  _check: function(){
    $this = ( $trigger.filter('.active').length > 0 ) ? $trigger.filter('.active') : false;

    if( $this ){

      prefix = $this.attr('data-prefix');
      toReplace = $this.attr('data-replace');

      $target.find('a').each(function(){
        link = $(this).attr('href');
        link = link.replace(toReplace, prefix);
        $(this).attr('href', link);
      });
    }
    else{

      $this = $trigger.first();
      prefix = $this.attr('data-prefix');
      toReplace = $this.attr('data-replace');

      $target.find('a').each(function(){
        link = $(this).attr('href');
        link = link.replace(toReplace, prefix);
        $(this).attr('href', link);
      });

      $this.addClass('active');
    }
  },

  init: function(){
    this._construct();
    //

    fxLT._check();

    $trigger
    .on('click', function(event){
      event.preventDefault();
      // --

      fxLT._shuffle( $(this) );
    });
  }
}

fx_mapTabs_peltre = {

  _construct: function(){
    $location_triggers = $('.location-item');
    $location_maps = $('.locations-map-list');
    $html_body = $('html, body');
    fxMT = this;
  },

  _scrollBody: function(){
    mapTop = $location_maps.offset().top - 150;
    $html_body
    .animate({
      scrollTop:mapTop
    }, '500', 'swing');
  },

  _srollToMap: function($this){
    ref = $this.attr('target');

    if( ref != "" && ref != undefined){

      $ref = $('#'+ref);

      toScroll = $ref.attr('data-index') * $ref.width();
      $location_maps
      .stop().animate({
        scrollLeft:toScroll
      }, '500', 'swing');

      $location_triggers.removeClass('active');
      $this.addClass('active');

      if( $(window).width() >= 768 ){
        fxMT._scrollBody();
      }
    }
  },

  init: function(){
    this._construct();
    // --

    $location_triggers
    .on('click', function(event){
      event.preventDefault();
      // --

      fxMT._srollToMap( $(this) );
    });

    $location_triggers
    .find('a')
    .on('click', function(event){
      event.stopPropagation();
      // --
    });
  }
}

fx_instagram_peltre = {

  _construct: function(){
    $instagram          = $('.instagram-container');
    $instagram_template = $('#instagram-template');
  },

  load: function(){
    this._construct();

    //--

    $instagram
    .on('willLoadInstagram', function(event, options) {  });

    $instagram
    .on('didLoadInstagram', function(event, response) {
      var compiled = _.template($instagram_template.html());
      $(this).html(compiled({ items: response.data }));
    });

    $instagram
    .instagram({
      userId: '1923664660',
      accessToken: '1923664660.e30200a.212fcd913c5b404b96365b5479cee3ca',
      count: 6,
    });
  }
}

// ... Punta Corcho ... //

fx_slider_punta_corcho = {

  _construct: function() {
    _sldThis = this;

    //--

    $main       = $('.owl-main');
    $carousel_1 = $('.owl-carousel-1');
    $carousel_2 = $('.owl-carousel-2');
  },

  main: function() {
    this._construct();

    //--

    $main
    .owlCarousel({
      autoplay: true,
      autoplayHoverPause: true,
      dots: true,
      items: 1,
      loop: true,
      margin: 0,
      nav: false
    });
  },

  carousel1: function() {
    this._construct();

    //--

    $carousel_1
    .owlCarousel({
      autoplay: true,
      autoplayTimeout:2500,
      autoplayHoverPause: true,
      dots: false,
      items: 4,
      loop: true,
      nav: true,
      navText: ["", ""],
      responsive:{

        1024:{
          items: 4,
          dots:false,
          nav:true
        },

        600:{
          items: 2,
          dots:true,
          nav:false
        },

        0:{
          items: 1,
          dots: true,
          nav:false
        }
      },
    });
  },

  carousel2: function() {
    this._construct();

    //--

    $carousel_2
    .owlCarousel({
      autoplay: false,
      autoplayHoverPause: true,
      dots: false,
      items: 1,
      loop: true,
      nav: true,
      navText: ["", ""],
      responsive:{

        1200:{
          dots:false,
          nav:true
        },

        0:{
          dots:true,
          nav:false
        }
      }
    });
  }
}


fx_instagram_punta_corcho = {

  _construct: function(){
    $instagram          = $('.instagram-container');
    $instagram_template = $('#instagram-template');
  },

  load: function(){
    this._construct();

    //--

    $instagram
    .on('willLoadInstagram', function(event, options) {  });

    $instagram
    .on('didLoadInstagram', function(event, response) {
      var compiled = _.template($instagram_template.html());
      $(this).html(compiled({ items: response.data }));
    });

    $instagram
    .instagram({
      userId: '3914240048',
      accessToken: '3914240048.d89fc42.c9283ab71e98441c895aa89a8c2bd3bc',
      count: 6,
    });
  }
}

// ... Cranker ... //

fx_slider_cranker = {

  _construct: function () {
    _sldThis = this;

    //--

    $main = $('.owl-main');
    $carousel_1 = $('.owl-carousel-1');
    $team = $('.owl-carousel-team');
    $carousel_2 = $('.owl-carousel-2');
  },

  main: function () {
    this._construct();

    //--

    $main
      .owlCarousel({
        autoplay: true,
        autoplayHoverPause: true,
        dots: true,
        items: 1,
        loop: true,
        margin: 0,
        nav: false
      });
  },

  carousel1: function () {
    this._construct();

    //--

    $carousel_1
      .owlCarousel({
        autoplay: true,
        autoplayTimeout: 2500,
        autoplayHoverPause: true,
        dots: false,
        items: 4,
        loop: true,
        nav: true,
        navText: ["", ""],
        responsive: {

          1024: {
            items: 4,
            dots: false,
            nav: true
          },

          600: {
            items: 2,
            dots: true,
            nav: false
          },

          0: {
            items: 1,
            dots: true,
            nav: false
          }
        },
      });
  },

  team: function () {
    this._construct();

    //--

    $team
      .owlCarousel({
        autoplay: true,
        autoplayTimeout: 2500,
        autoplayHoverPause: true,
        dots: false,
        items: 3,
        loop: true,
        margin: 30,
        nav: true,
        navText: ["", ""],
        responsive: {

          1024: {
            items: 3,
            dots: false,
            nav: true
          },

          600: {
            items: 2,
            dots: true,
            nav: false
          },

          0: {
            items: 1,
            dots: true,
            nav: false
          }
        },
      });
  },

  carousel2: function () {
    this._construct();

    //--

    $carousel_2
      .owlCarousel({
        autoplay: false,
        autoplayHoverPause: true,
        dots: false,
        items: 1,
        loop: true,
        nav: true,
        navText: ["", ""],
        responsive: {

          1200: {
            dots: false,
            nav: true
          },

          0: {
            dots: true,
            nav: false
          }
        }
      });
  }
}


fx_instagram_cranker = {

  _construct: function () {
    $instagram = $('.instagram-container');
    $instagram_template = $('#instagram-template');
  },

  load: function () {
    this._construct();

    //--

    $instagram
      .on('willLoadInstagram', function (event, options) { });

    $instagram
      .on('didLoadInstagram', function (event, response) {
        var compiled = _.template($instagram_template.html());
        $(this).html(compiled({ items: response.data }));
      });

    $instagram
      .instagram({
        userId: '4704124920',
        accessToken: '4704124920.45d5044.462b3574f1954559843dfede7e7b65e4',
        count: 6,
      });
  }
}

/** Video Backwards **/
  fx_video_cranker = {

    _construct: function(){
      video = document.getElementById("video");
    },

    init: function(){
      this._construct();
      // --

      var intervalRewind;
      video.play();

      $(video).on('play', function () {
        video.playbackRate = 1.0;
        clearInterval(intervalRewind);
      });
      $(video).on('ended', function () {
        rewind(0.2);
      });

      function rewind(rewindSpeed) {
        clearInterval(intervalRewind);
        var startSystemTime = new Date().getTime();
        var startVideoTime = video.duration;

        intervalRewind = setInterval(function () {
          video.playbackRate = 1.0;
          var elapsed = new Date().getTime() - startSystemTime;
          if (startVideoTime - (elapsed / 1000.0) <= 0){
            clearInterval(intervalRewind);
            video.currentTime = 0;
            setTimeout(function() {
              video.play();
            }, 3000);
          } else {
            video.currentTime = startVideoTime - (elapsed / 1000.0);
          }
        }, 100);
      }
    }
  }


// ... Tajana ... //

fx_slider_tajana = {

  _construct: function () {
    _sldThis = this;

    //--

    $main = $('.owl-main');
    $carousel_1 = $('.owl-carousel-1');
    $team = $('.owl-carousel-team');
    $carousel_2 = $('.owl-carousel-2');
  },

  main: function () {
    this._construct();

    //--

    $main
    .owlCarousel({
      autoplay: true,
      autoplayHoverPause: true,
      dots: true,
      items: 1,
      loop: true,
      margin: 0,
      nav: false
    });
  },

  carousel1: function () {
    this._construct();

    //--

    $carousel_1
    .owlCarousel({
      autoplay: true,
      autoplayTimeout: 2500,
      autoplayHoverPause: true,
      dots: false,
      items: 3,
      loop: true,
      nav: true,
      navText: ["", ""],
      margin: 20,
      responsive: {

        1024: {
          items: 3,
          dots: false,
          nav: true
        },

        600: {
          items: 2,
          dots: true,
          nav: false
        },

        0: {
          items: 1,
          dots: true,
          nav: false
        }
      },
    });
  },

  team: function () {
    this._construct();

    //--

    $team
    .owlCarousel({
      autoplay: true,
      autoplayTimeout: 2500,
      autoplayHoverPause: true,
      dots: false,
      items: 3,
      loop: true,
      margin: 30,
      nav: true,
      navText: ["", ""],
      responsive: {

        1024: {
          items: 3,
          dots: false,
          nav: true
        },

        600: {
          items: 2,
          dots: true,
          nav: false
        },

        0: {
          items: 1,
          dots: true,
          nav: false
        }
      },
    });
  },

  carousel2: function () {
    this._construct();

    //--

    $carousel_2
    .owlCarousel({
      autoplay: false,
      autoplayHoverPause: true,
      dots: false,
      items: 1,
      loop: true,
      nav: true,
      navText: ["", ""],
      responsive: {

        1200: {
          dots: false,
          nav: true
        },

        0: {
          dots: true,
          nav: false
        }
      }
    });
  }
}

fx_instagram_tajana = {

  _construct: function () {
    $instagram = $('.instagram-container');
    $instagram_template = $('#instagram-template');
  },

  load: function () {
    this._construct();

    //--

    $instagram
      .on('willLoadInstagram', function (event, options) { });

    $instagram
      .on('didLoadInstagram', function (event, response) {
        var compiled = _.template($instagram_template.html());
        $(this).html(compiled({ items: response.data }));
      });

    $instagram
      .instagram({
        userId: '5432971579',
        accessToken: '5432971579.756c8d3.e2a22fc18396448f9862cf632815cc46',
        count: 4,
      });
  }
}


/** Fancybox **/

fx_fancy = {

  _construct: function() {
    _fancyThis = this;

    //--

    $iframe = $("a.fbox-iframe");
    $image  = $("a.fbox-image");
  },

  //--

  _iframe: function() {

    $iframe
    .fancybox({
      type: 'iframe',
      autoResize: true,
      fitToView: true,
      aspectRatio: true,
    });
  },

  _image: function() {

    $image
    .fancybox({
      type: 'image',
      autoResize: true,
      fitToView: true,
      aspectRatio: true,
    });
  },


  make: function() {
    this._construct();

    //--

    _fancyThis._iframe();
    _fancyThis._image();
  }
}
